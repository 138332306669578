<!--
 * @Description: 我的页面配置
-->
<template>
  <div class="wrap">
    <div style="padding-right:360px">
      <phone-ctn>
        <agreement-bar></agreement-bar>
      </phone-ctn>
    </div>

    <!-- 导航按钮 -->
    <config-ctn title="预约协议页面">


      <div class="p10">
        <label class=" f12">此配置适用于----- 预约协议页面</label>

        <config-item label='协议内容'>

          <FaRichText v-model="project.setting.agreement.title" placeholder="请输入协议内容" />

          <!-- <el-input placeholder="请输入协议内容"  v-model="project.setting.agreement.title" size="large"></el-input> -->
          <!-- <textarea v-model="project.setting.agreement.title"> 12313212313213213 </textarea> -->
        </config-item>


        <config-item label='登录按钮文字'>
          <el-input placeholder="请输入名字" v-model="project.setting.agreement.btnTitle" size="small"
            @change="handleChange"></el-input>
        </config-item>

        <config-item label='按钮背景颜色'>
          <config-color-picker v-model="project.setting.agreement.btnBgColor"></config-color-picker>
        </config-item>

        <config-item label='按钮高度(px,vw.%)'>
          <el-input placeholder="请输入数字" size="small" v-model="project.setting.agreement.btnHeight"></el-input>
        </config-item>

        <config-item label='按钮宽度(px,vw.%)'>
          <el-input placeholder="请输入屏幕宽度的百分比,一般默认90" size="small"
            v-model="project.setting.agreement.btnWidth"></el-input>
        </config-item>

        <config-item label='按钮上边圆角'>
          <el-input placeholder="请输入数字" size="small" v-model="project.setting.agreement.btnUpperRadius"></el-input>
        </config-item>

        <config-item label='按钮下边圆角'>
          <el-input placeholder="请输入数字" size="small" v-model="project.setting.agreement.btnLowerRadius"></el-input>
        </config-item>

        <config-item label='按钮上边间距'>
          <el-input placeholder="请输入数字" size="small" v-model="project.setting.agreement.btnMarginTop"></el-input>
        </config-item>

        <config-item label='按钮下边间距'>
          <el-input placeholder="请输入数字" size="small" v-model="project.setting.agreement.btnMarginBottom"></el-input>
        </config-item>



      </div>
    </config-ctn>

  </div>
</template>

<script>
import AgreementBar from "@/components/agreementBar";
import { mapGetters } from "vuex";

export default {
  name: "agreementTpl",

  components: {
    AgreementBar,
  },

  computed: {
    ...mapGetters(["project"]),
  },
  mounted() {
    console.log('====>', this.project);
    if (this.project.setting.agreement == null) {
      this.project.setting.agreement = {
        title: "协议内容"
      }
    }
  },

  methods: {
    handleChange() {
      console.log('The message is changed.');
    }

  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>