<!--
 * @Description: 预约协议
-->
<template>

    <div class="agreement" :style="[getStyle()]">
        <!-- logo区域 -->

        <div class="pt10 pb10 content">
            <div class="rich" :style="[subTitleStyle()]" v-html="project.setting.agreement.title"></div>
        </div>


        <!-- 登录按钮区域 -->
        <input type="button" class="btn" :style="[btnStyle()]" :value="project.setting.agreement.btnTitle || ''" />

    </div>



</template>

<script>
import {
    mapGetters
} from "vuex";

export default {
    name: "agreementBar",

    data() {
        return {
            headImg: '',
        };
    },

    computed: {
        ...mapGetters(["project"]),
    },
    mounted() {
        console.log('进入预约协议设置页--------->', this.project);
        this.headImg = this.project.setting.agreement.headImg;
    },
    methods: {
        //背景图
        getStyle() {
            //页面的背景图
            return {
                'min-height': '812px',
            }
        },

        // 正文样式
        subTitleStyle() {
            return {
                width: '90%'
                // color: this.styles.infoColor,
                // fontSize: this.$unit(this.styles.infoSize),
                // fontWeight: this.styles.infoWeight
            }
        },

        // 按钮样式
        btnStyle() {
            return {
                height: this.project.setting.agreement.btnHeight,
                width: this.project.setting.agreement.btnWidth,
                color: this.project.setting.agreement.btnTitleColor,
                fontSize: this.project.setting.agreement.btnTitleSize,
                fontWeight: 400,
                background: this.project.setting.agreement.btnBgColor,
                borderRadius: `${this.project.setting.agreement.btnUpperRadius}px ${this.project.setting.agreement.btnUpperRadius}px ${this.project.setting.agreement.btnLowerRadius}px ${this.project.setting.agreement.btnLowerRadius}px`,
                marginTop: `${this.project.setting.agreement.btnMarginTop}px `,
                marginBottom: `${this.project.setting.agreement.btnMarginBottom}px `,
            }
        }
    }
}
</script>

<style>
.agreement {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    /* color: #d2d2d2; */
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginTitle {
    width: 100%;
    height: 30px;
    font-family: Alibaba PuHuiTi 3.0, Alibaba PuHuiTi 30;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    line-height: 0px;
    text-align: center;
    font-style: normal;
    text-transform: none;

}


.btn {
    background: rgb(188, 175, 166);
    border-radius: 50px;
    text-align: center;
    color: white;
    font-size: 16px;
    margin-top: 10px;
    width: 90%;
}
</style>